import React, { Component } from 'react';
import { Breadcrumb, Divider, Table, Button, Row, Space, Modal, Form, message, TimePicker, DatePicker } from 'antd';
import {
    RollbackOutlined,
    EditFilled,
    PlusCircleFilled
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { API } from "../util/config";
import axios from 'axios';
import { formateDateTH, Today } from '../util/formatDate'
import moment from 'moment';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const key = 'updatable';

class display extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stocks: {},
            isModalAddVisible: false,
            type_id: '',
            type_name: '',
            today: '',
            accessToken: '',
            stocksList: [],
            pagination: {
                // current: 1,
                pageSize: 10,
            },
            columns: [
                {
                    title: 'ลำดับที่',
                    dataIndex: 'order',
                    // defaultSortOrder: 'descend',
                    sorter: (a, b) => a.order - b.order,
                },
                {
                    title: 'งวดวันที่',
                    dataIndex: 'dateTH',
                },
                {
                    title: 'รางวัล',
                    dataIndex: 'type_id',
                    render: (text, record) => (
                        text === 1 
                        ? 
                        <>บน: {record.award_hanoi_1Top}, ล่าง: {record.award_hanoi_1Down}</>
                        : 
                        text === 3 ?
                        <>{record.award_lao_1}</>
                        :
                        text === 4 ?
                        <>บน: {record.award_dowjones_1st}, ล่าง: {record.award_dowjones_2nd}</>
                        :
                        text === 5 ?
                        <>บน: {record.award_siam_1Top}, ล่าง: {record.award_siam_1Down}</>
                        :
                        text === 6 ?
                        <>บน: {record.award_3states_1st}, ล่าง: {record.award_3states_2nd}</>
                        : 
                        <></>
                    )
                },
                {
                    title: 'เวลาออกรางวัล',
                    dataIndex: 'time',
                },
                {
                    title: 'ตั้งค่า',
                    dataIndex: 'setting',
                    render: (text, record) => (
                        <Space size="middle">
                            {/* {record.award_date ==== moment().format('YYYY-MM-DD') && this.state.stocks.stocks_time > moment().format('HH:mm:ss') ? */}
                                <Link to={`/manage/stocks/${this.state.stocks.type_name_en}/${record.award_id}`}>
                                    <Button type="primary" icon={<EditFilled />}>
                                        จัดการตัวเลข
                                    </Button>
                                </Link>
                                {/* :
                                this.state.stocks.stocks_id ==== 28 ?
                                    <Link to={`/manage/stocks/${this.state.stocks.type_name_en}/${record.award_id}`}>
                                        <Button type="primary" icon={<EditFilled />}>
                                            จัดการตัวเลข
                                        </Button>
                                    </Link>
                                    :
                                    <Button type="primary" icon={<EditFilled />} disabled>
                                        จัดการตัวเลข
                                    </Button>
                            } */}
                        </Space>
                    ),
                },
            ],
            data: []
        };
    }

    async componentDidMount() {
        if (!localStorage.getItem('user')) {
            this.props.history.push("/login");
        } else {
            const user = JSON.parse(localStorage.getItem('user'))
            this.setState({ accessToken: user.accessToken }, () => this.getDataStocks())
            const today = Today()
            // console.log(today)
            this.setState({ today: today })
        }
    }

    async getDataStocks() {
        const stocksID = this.props.match.params.stocks
        await axios.get(`${API}/stocks/id/${stocksID}`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    console.log(data.result)
                    this.setState({ stocks: data.result })
                    this.getDataHisory(data.result.type_name_en, data.result.stocks_id)
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    async getDataHisory(type, id) {
        // console.log(`${API}/${type}/stocks/id/${id}`)
        await axios.get(`${API}/${type}/stocks/id/${id}`, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.setState({ stocksList: data.result })
                    var list = []
                    data.result.forEach((item, index) => {
                        list.push(
                            {
                                key: index + 1,
                                order: index + 1,
                                dateTH: formateDateTH(item.award_date),
                                time: this.state.stocks.stocks_time,
                                last: data.result.length,
                                ...item
                            }
                        )
                    });
                    this.setState({ data: list })
                } else {
                    console.log(data.message)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    //  modal Add
    showModalAdd = (id) => {
        this.setState({ isModalAddVisible: true })
    };

    handleOkAdd = async () => {
        // console.log(this.state.stocksList)
        // console.log(this.state.today +" > "+ this.state.stocksList[0].award_date)
        if (this.state.today > this.state.stocksList[0].award_date) {
            message.loading({ content: 'Loading...', key });
            this.addHisory()
        } else {
            message.error('เพิ่มข้อมูลได้วันล่ะครั้ง');
        }
    };

    async addHisory() {
        const stocksID = this.props.match.params.stocks
        const type = this.state.stocks.type_name_en
        const body = {
            date: this.state.today,
            stocksID: stocksID
        }
        await axios.post(`${API}/${type}/add`, body, { headers: { 'Authorization': `Bearer ${this.state.accessToken}` } })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.getDataStocks()
                    setTimeout(() => {
                        message.success({ content: 'ทำรายการเสร็จสิ้น!', key, duration: 2 })
                        this.setState({ isModalAddVisible: false })
                    }, 1000);
                } else {
                    console.log(data.message)
                    message.error('เกิดข้อผิดพลาด');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>RUAY</Breadcrumb.Item>
                    <Breadcrumb.Item>จัดการหวย</Breadcrumb.Item>
                    <Breadcrumb.Item>ออกรางวัล</Breadcrumb.Item>
                </Breadcrumb>

                <br />

                <Divider orientation="left"><h3>จัดการหวย - {this.state.stocks.stocks_name ? this.state.stocks.stocks_name : ''}</h3></Divider>
                <Row justify="end">
                    <Button shape="round" size="large" style={{ marginBottom: 20, marginRight: 10 }} onClick={() => this.props.history.goBack()} icon={<RollbackOutlined />}>ย้อนกลับ</Button>
                    {this.props.match.params.stocks == 34 ? 
                        <Button type="primary" onClick={() => this.setState({ isModalAddVisible: true })} icon={<PlusCircleFilled />} shape="round" size="large" style={{ marginBottom: 20 }} disabled={this.state.stocks_dateTime ? true : false} >
                            เพิ่มงวด
                        </Button>
                    : <></>
                    }
                </Row>

                <Table columns={this.state.columns} dataSource={this.state.data} pagination={this.state.pagination} bordered />

                {/* modal add */}
                <Modal title="เพิ่ม" visible={this.state.isModalAddVisible} onOk={this.handleOkAdd.bind(this)}
                    onCancel={() => this.setState({ isModalAddVisible: false })} cancelText="ยกเลิก" okText="ตกลง"
                >
                    <Form.Item>
                        <p>งวดที่ออก</p>
                        <DatePicker defaultValue={moment(this.state.today, 'YYYY/MM/DD')} format='YYYY/MM/DD' style={{ width: 300 }} disabled />
                    </Form.Item>
                    <Form.Item>
                        <p>เวลาออกรางวัล</p>
                        <TimePicker defaultValue={moment(this.state.stocks.stocks_time, 'HH:mm:ss')} style={{ width: 300 }} disabled />
                    </Form.Item>
                </Modal>
            </>
        );
    }
}

export default withRouter(display)